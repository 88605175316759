import { jsx } from "react/jsx-runtime";
import { usePage } from "@tecnobit-srl/router";
import { lazy, Suspense } from "react";
const Debug = lazy(
  () => import("./debug-component-CW7Uejs9.js").then((module) => ({
    default: module.DebugComponent
  }))
);
function DebugBar({
  props
}) {
  var _a;
  const page = usePage();
  if (!((_a = page.user) == null ? void 0 : _a.is_admin)) {
    return null;
  }
  return /* @__PURE__ */ jsx(Suspense, { fallback: null, children: /* @__PURE__ */ jsx(Debug, { props }) });
}
export {
  DebugBar
};
